/* ==========================================================================
   #SECTION
   ========================================================================== */

.o-section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &:not(.o-section--narrow) {
    @include container;
  }

  @include bp(large) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.o-section--narrow {
  @include container(true);
}

.o-section__title {
  @extend .o-h2;
  margin-bottom: 2rem;

  @include bp(large) {
    margin-bottom: 3rem;
  }
}

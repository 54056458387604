/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include hidden-visually();

  &.is-focusable:active,
  &.is-focusable:focus {
    @include clear-hidden-visually;
  }
}

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;

  @include bp(medium) {
    display: block;
  }
}

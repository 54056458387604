/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-container:not(.o-container--narrow) {
  @include container;
}

.o-container--narrow {
  @include container(true);
}

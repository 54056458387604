/* ==========================================================================
   #LOGOS
   ========================================================================== */

.c-logos__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.c-logos__item {
  text-align: center;
  padding: 1rem 1rem;
  width: 50%;

  @include bp(large) {
    width: 25%;
    padding: 2rem;
  }
}

.c-logos__link {
  display: inline-block;

  &:hover {
    transform: scale(1.05);
  }

  img {
    max-height: 150px;
  }
}

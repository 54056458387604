/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;
  transition: $global-transition;

  &:hover {
    color: $color-hover;
  }

  &:focus {
    outline: 3px solid $color-focus-text;
    color: $color-focus-text;
    background-color: $color-focus-bg;
    text-decoration: none;
  }
}

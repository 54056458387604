///* ========================================================================
//   #CENTER
//   ======================================================================== */

@mixin center-vertical() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-horizontal() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-all() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
$list-item-padding: 1.5rem;
$list-item-margin: 1rem;
$list-bullet-width: 0.7rem;
$list-bullet-height: $list-bullet-width;
$sublist-bullet-width: 0.5rem;
$sublist-bullet-height: $sublist-bullet-width;
$list-bullet-color: $color-primary;

ul,
ol {
  list-style: none;
  padding-left: 0;
  margin-left: 0;

  li {
    position: relative;
    padding-left: calc(#{$list-bullet-width} + #{$list-item-padding});
    margin-bottom: $list-item-margin;
  }

  ul,
  ol {
    margin-top: $list-item-margin;
  }
}

ul {
  > li {
    &::before {
      position: absolute;
      left: 0;
      top: $list-item-margin;
      content: '';
      display: block;
      width: $list-bullet-width;
      height: $list-bullet-height;
      border-radius: 100%;
      background-color: $list-bullet-color;
    }
  }

  // 2nd level
  ul {
    > li {
      &::before {
        top: calc(#{$list-item-margin});
        width: $sublist-bullet-width;
        height: $sublist-bullet-height;
        border-radius: 0;
      }
    }
  }
}

ol {
  counter-reset: section;

  > li {
    &::before {
      position: absolute;
      left: 0;
      counter-increment: section;
      content: counters(section, '.') '.';
      font-weight: bold;
    }
  }

  // 2nd level
  ol {
    > li {
      &::before {
        content: counter(section, upper-alpha) '.';
      }
    }
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #MARGIN
   ========================================================================== */

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .u-#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .u-#{$abbrev}t-#{$size},
    .u-#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }

    .u-#{$abbrev}r-#{$size},
    .u-#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }

    .u-#{$abbrev}b-#{$size},
    .u-#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .u-#{$abbrev}l-#{$size},
    .u-#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

@each $breakpoint in $breakpoints-list {
  @include bp($breakpoint) {
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .u-#{$abbrev}-#{$size}\@#{$breakpoint} {
          #{$prop}: $length !important;
        }

        .u-#{$abbrev}t-#{$size}\@#{$breakpoint},
        .u-#{$abbrev}y-#{$size}\@#{$breakpoint} {
          #{$prop}-top: $length !important;
        }

        .u-#{$abbrev}r-#{$size}\@#{$breakpoint},
        .u-#{$abbrev}x-#{$size}\@#{$breakpoint} {
          #{$prop}-right: $length !important;
        }

        .u-#{$abbrev}b-#{$size}\@#{$breakpoint},
        .u-#{$abbrev}y-#{$size}\@#{$breakpoint} {
          #{$prop}-bottom: $length !important;
        }

        .u-#{$abbrev}l-#{$size}\@#{$breakpoint},
        .u-#{$abbrev}x-#{$size}\@#{$breakpoint} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Some special margin utils

    .u-m-auto\@#{$breakpoint} {
      margin: auto !important;
    }

    .u-mt-auto\@#{$breakpoint},
    .u-my-auto\@#{$breakpoint} {
      margin-top: auto !important;
    }

    .u-mr-auto\@#{$breakpoint},
    .u-mx-auto\@#{$breakpoint} {
      margin-right: auto !important;
    }

    .u-mb-auto\@#{$breakpoint},
    .u-my-auto\@#{$breakpoint} {
      margin-bottom: auto !important;
    }

    .u-ml-auto\@#{$breakpoint},
    .u-mx-auto\@#{$breakpoint} {
      margin-left: auto !important;
    }
  }
}

.u-m-auto {
  margin: auto !important;
}

.u-mt-auto,
.u-my-auto {
  margin-top: auto !important;
}

.u-mr-auto,
.u-mx-auto {
  margin-right: auto !important;
}

.u-mb-auto,
.u-my-auto {
  margin-bottom: auto !important;
}

.u-ml-auto,
.u-mx-auto {
  margin-left: auto !important;
}

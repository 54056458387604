/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include bp(large) {
    position: relative;
    margin-top: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.c-header__logo {
  display: block;
  width: 10rem;

  @include bp(xlarge) {
    @include center-all;
    z-index: 102;
    width: 13rem;
  }
}

$header-hamburger-size: 3rem;
$header-hamburger-lines-trans: 0.3s;

// Generate lines for line button
@mixin line($color, $button-size, $button-line-h, $transition) {
  display: block;
  width: $button-size;
  height: $button-line-h;
  background: $color;
  transition: $transition;
}

.c-header__hamburger {
  position: relative;
  z-index: 101;
  float: right;
  height: 36px;
  padding: 0;
  transition: $header-hamburger-lines-trans;
  cursor: pointer;
  user-select: none;
  border: 0;
  background: transparent;
  vertical-align: top;
  margin-left: auto;

  &:hover {
    opacity: 1;
  }

  @include bp(large) {
    display: none;
  }
}

.c-header__hamburger-lines {
  position: relative;

  &,
  &:before,
  &:after {
    @include line(
      $color-black,
      $header-hamburger-size,
      3px,
      $header-hamburger-lines-trans
    );
  }

  &:before,
  &:after {
    position: absolute;
    left: 0;
    content: '';
    transform-origin: $header-hamburger-size/14 center;
  }

  &:before {
    top: 9px;
  }

  &:after {
    top: -9px;
  }

  .is-nav-open & {
    background-color: transparent;

    &:before,
    &:after {
      transform-origin: 50% 50%;
      top: 0;
      width: $header-hamburger-size;
      background: $color-black;
    }

    &:before {
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &:after {
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}

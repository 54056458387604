/* ==========================================================================
   #SOCIAL LINKS
   ========================================================================== */

.c-social-links {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.c-social-links__item {
  margin: 0 0.5rem;
}

.c-social-links__link {
  display: flex;
  align-items: center;
  color: $color-text;
}

.c-social-links__icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: currentColor;

  &.is-facebook {
    width: 2rem;
  }
}

/* ==========================================================================
   #
   ========================================================================== */

.heading,
.o-h1,
.o-h2,
.o-h3,
.o-h4,
.o-h5,
.o-h6 {
  @include heading;
}

.o-h1 {
  font-size: 2rem;

  @include bp(large) {
    font-size: 4.8rem;
  }
}

.o-h2 {
  font-size: 2.6rem;

  @include bp(large) {
    font-size: 3.6rem;
  }
}

.o-h3 {
  font-size: 2.2rem;

  @include bp(large) {
    font-size: 2.8rem;
  }
}

.o-h4 {
  font-size: 1.8rem;

  @include bp(large) {
    font-size: 1.8rem;
  }
}

.o-h5 {
  font-size: 1.6rem;

  @include bp(large) {
    font-size: 1.6rem;
  }
}

.o-h6 {
  font-size: 1.4rem;
}

.o-text-bigger {
  @include text-big;
}

.o-text-lead {
  @include text-lead;
}

.o-text-big {
  @include text-big;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */

@mixin container($narrow: false) {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  padding-left: $container-padding;
  padding-right: $container-padding;

  @if $narrow {
    max-width: calc(#{$container-narrow-width} + 2 * #{$container-padding});
  }
  @else {
    max-width: calc(#{$container-width} + 2 * #{$container-padding});
  }
}

@mixin container-stretch() {
  @include bp-down(medium) {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

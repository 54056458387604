/* ==========================================================================
   #INTRO
   ========================================================================== */

.c-intro {
  font-size: 1.7rem;
  line-height: 1.7;

  a {
    padding-left: 2px;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    transition: color ease 0.3s;

    &:before {
      position: absolute;
      bottom: -2px;
      left: -3px;
      right: -3px;
      height: 2px;
      background: $color-primary;
      content: '';
      transition: bottom 0.3s;
    }

    &:after {
      position: absolute;
      bottom: -2px;
      left: -3px;
      width: 2px;
      height: 2px;
      background: $color-primary;
      content: '';
      transition: height 0.3s;
    }

    &:hover {
      color: $color-primary;

      &:before {
        bottom: calc(100%);
        opacity: 1;
      }

      &:after {
        height: calc(100% + 2px);
      }
    }
  }

  @include bp(large) {
    font-size: 2.3rem;
    line-height: 1.7;
  }
}

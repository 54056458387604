/* ==========================================================================
   #COLORS
   ========================================================================== */

.u-color-primary {
  color: $color-primary !important;
}

.u-color-secondary {
  color: $color-secondary !important;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */

.o-media {
  position: relative;
  padding-bottom: 56.25%;
  background: $color-fill;

  iframe {
    @include fill-abs;
    border: 0 !important;
  }
}

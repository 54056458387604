/* ==========================================================================
   #TRSUTBOX
   ========================================================================== */

.c-trustbox {
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.tp-widget-rating {
  font-size: 30px !important;
}

@import 'magnific-popup/src/css/main';

.mfp-bottom-bar {
  margin-top: -3rem;
}

.mfp-title {
  line-height: 1.33;

  b {
    font-weight: 700;
  }
}

.mfp-wrap {
  &:focus {
    background: none;
  }
}

/* ==========================================================================
   #SLIDER
   ========================================================================== */

@import 'slick-carousel/slick/slick.scss';

.o-slider-container {
  position: relative;
}

$dot-size: 1rem;
$dot-margin-right: 1rem;
$dot-bg: $color-very-light-grey;
$dot-border-color: $dot-bg;
$dot-active-bg: $color-primary;
$dot-active-border-color: $dot-active-bg;

.slick-initialized .slick-track {
  display: flex;
  align-items: center;
}

// Custom slider dots
.slick-dots {
  position: absolute;
  bottom: -1.5rem;
  @include list-reset;
  width: 100%;
  display: flex;
  justify-content: center;

  li {
    position: relative;
    // width: $dot-size;
    // height: $dot-size;

    &:not(:last-of-type) {
      margin-right: $dot-margin-right;
    }
  }

  button {
    display: block;
    padding: 0;
    margin: 0;
    background-color: $dot-bg;
    width: $dot-size;
    height: $dot-size;
    border: 2px solid $dot-border-color;
    border-radius: $dot-size;
    text-align: center;
    font-size: 0;
    transition: all ease 0.3s;

    &:active,
    &:focus {
      outline: none;
    }

    &:hover {
      border-color: $dot-active-border-color;
    }
  }
}

.slick-active > button {
  width: $dot-size * 2.5;
  background-color: $dot-active-bg !important;
  border-color: $dot-active-border-color;
}

// .slick-prev
.slick-arrow {
  @include center-vertical;
  display: block;
  padding: 0;
  z-index: 1;
  border: 0;
  background: transparent;
  transition: $global-transition;

  &:hover {
    opacity: 0.75;
  }
}

.slick-prev {
  left: -1.5rem;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 15px 20px 0;
    border-color: transparent $color-black transparent transparent;
  }
}

.slick-next {
  right: -1.5rem;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 15px;
    border-color: transparent transparent transparent $color-black;
  }
}

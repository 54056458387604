/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 3rem;
  text-align: center;
  justify-content: center;
}

.c-footer__contact {
  font-size: 1.5rem;
}

.c-footer__social {
  margin-top: 1.5rem;
  justify-content: center;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 2.5rem $container-padding 2rem;
  background: rgba($color-white, 1);
  transition: transform 350ms ease-in-out;
  will-change: transform;
  pointer-events: none;
  overflow-y: auto;
  transform: translateY(-100%);

  .is-nav-open & {
    transform: translateY(0);
    z-index: 100;
    pointer-events: auto;
  }

  @include bp(large) {
    position: static;
    height: auto;
    padding: 0;
    background: transparent;
    transform: none;
    pointer-events: auto;
    flex: 1;
    overflow: visible;

    .is-nav-open & {
      transform: none;
      opacity: 1;
    }
  }
}

.c-main-nav__list {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include bp(large) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  @include bp(xlarge) {
    justify-content: flex-start;
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp(large) {
    margin-left: 0.6rem;
  }
}

.c-main-nav__item--social {
  order: 1;

  &:first-child {
    margin-top: 1.5rem;
  }

  @include bp(large) {
    order: inherit;

    &:first-child {
      margin-top: 0;
    }
  }

  @include bp(xlarge) {
    &:first-child {
      margin-top: 0;
      margin-left: 0;
    }

    & + .c-main-nav__item:not(.c-main-nav__item--social) {
      margin-left: auto;
    }
  }
}

.c-main-nav__link {
  display: block;
  padding: 0.25em 0;
  font-size: 2.5rem;
  font-family: $font-secondary;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-text;
  text-decoration: none;

  .is-active & {
    color: $color-primary;
  }

  @include bp(large) {
    padding: 0.4rem 0.2rem;
    font-size: 1.4rem;
    font-weight: $font-weight-semibold;
    border-style: solid;
    border-width: 2px 0;
    border-color: transparent;
    letter-spacing: -0.02em;

    &:hover {
      color: $color-primary;
    }

    .is-active & {
      color: $color-text;
      border-bottom-color: $color-text;
    }
  }
}

@include bp(large) {
  .c-main-nav__link--btn {
    display: flex;
    align-items: center;
    height: 2.9rem;
    padding: 0 1rem;
    color: $color-text !important;
    border: 0;

    &:hover {
      color: $color-text;
    }

    &.is-primary {
      background: $color-primary;

      &:hover {
        background: $color-primary;
      }
    }

    &.is-secondary {
      background: $color-secondary-light;

      &:hover {
        background: $color-secondary;
      }
    }
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */

/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */

.o-list-bare {
  @include list-reset;
}

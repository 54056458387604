/* ==========================================================================
   #SKIP LINKS
   ========================================================================== */

.c-skip-links {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.c-skip-links__link {
  @include hidden-visually(true);
  white-space: nowrap;
}

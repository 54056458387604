/* ==========================================================================
   #PAGE TITLE
   ========================================================================== */

.c-page-title {
  margin-top: 3.5rem;

  @include bp(large) {
    margin-top: 8rem;
    margin-bottom: -4rem;
  }
}

.c-page-title__name {
  margin-bottom: 0;
}

.c-page-title__text {
  margin-top: 2rem;
  font-size: 1.7rem;

  @include bp(large) {
    font-size: 1.8rem;
  }
}

/* ==========================================================================
   #LIST RESET
   ========================================================================== */

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin-bottom: 0;

    &::before {
      display: none;
      content: '';
    }
  }
}

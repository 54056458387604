///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-primary: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-secondary: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;

$font-weight-semibold: 500;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

$breakpoints-list: ('small', 'medium', 'large', 'xlarge', 'full');

/* Spaces and sizes
    ========================================================================== */
$container-padding: 5vw;
$container-width: 138rem;
$container-narrow-width: 80rem; //102.4rem;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;

$color-primary: #c7491c;
$color-primary-dark: darken($color-primary, 15%);
$color-secondary: #e69e2e;
$color-secondary-light: #f2bf41;

// Text
$color-bg: $color-white;
$color-text: $color-black;

$color-focus-bg: $color-secondary-light;
$color-focus-text: $color-black;

// Links
$color-link: $color-primary;
$color-hover: $color-primary-dark;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

$spacer: 10px;
$spacers: (
  0: 0,
  1: (
    $spacer,
  ),
  2: (
    $spacer * 2,
  ),
  3: (
    $spacer * 3,
  ),
  4: (
    $spacer * 4,
  ),
  5: (
    $spacer * 5,
  ),
  6: (
    $spacer * 6,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 9,
  ),
  10: (
    $spacer * 10,
  ),
  11: (
    $spacer * 11,
  ),
  12: (
    $spacer * 12,
  ),
  13: (
    $spacer * 13,
  ),
  14: (
    $spacer * 14,
  ),
  15: (
    $spacer * 15,
  ),
);

/* ==========================================================================
   #BIOS
   ========================================================================== */

.c-bios__item {
  & + & {
    margin-top: 4rem;
  }

  @include bp(medium) {
    & + & {
      margin-top: 8rem;
    }
  }
}

.c-bios__item-img {
  margin-bottom: 1.5rem;

  @include bp(medium) {
    float: left;
    width: 300px;
    margin-right: 3rem;
  }
}

.c-bios__item-container {
  overflow: hidden;
}

.c-bios__item-more {
  display: none;
  clear: both;
}

.c-bios__item-more-btn {
  margin-top: 1rem;
  padding: 0;
  color: $color-primary;
  border: 0;
  background: transparent;
  border-bottom: 1px solid currentColor;

  .is-active {
    display: none;
  }

  &.is-active {
    span {
      display: none;
    }

    .is-active {
      display: inline;
    }
  }

  @include bp(medium) {
    margin-top: 1.5rem;
  }
}

/* ==========================================================================
   #WYSIWG
   ========================================================================== */

.o-wysiwyg {
  p {
    line-height: 1.7;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    @extend .o-h1;
  }

  h2 {
    @extend .o-h2;
  }

  h3 {
    @extend .o-h3;
  }

  h4 {
    @extend .o-h4;
  }

  h5 {
    @extend .o-h5;
  }

  h6 {
    @extend .o-h6;
  }
}

/* ==========================================================================
   #REVIEWS
   ========================================================================== */

// .c-reviews {

// }

.c-reviews__list {
  @include bp(medium) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.c-reviews__item {
  overflow: hidden;

  & + & {
    margin-top: 4rem;
  }

  @include bp(medium) {
    width: 50%;
    padding: 0 2rem;
    margin-top: 5rem;

    & + & {
      margin-top: 5rem;
    }
  }

  @include bp(large) {
    width: 100%;
  }

  @include bp(xlarge) {
    padding: 0 3rem;
    margin-top: 6rem;

    & + & {
      margin-top: 6rem;
    }
  }
}

.c-reviews__item-name {
  font-size: 2rem;
  font-weight: bold;
}

.c-reviews__item-title {
  font-weight: bold;
  text-decoration: none;

  &[href]:hover {
    text-decoration: underline;
  }
}

.c-reviews__item-text {
  margin-top: 1rem;
}

.c-reviews__item-link {
  display: inline-block;
  margin-top: 0.5rem;
}

///* ========================================================================
//   #HIDDEN-VISUALLY
//   ======================================================================== */

// Mixin to quickly apply accessible hiding to elements.
@mixin hidden-visually($focusable: false) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;

  @if $focusable {
    &:active,
    &:focus {
      @include clear-hidden-visually;
    }
  }
}

@mixin clear-hidden-visually {
  clip: auto !important;
  clip-path: none !important;
  height: auto !important;
  margin: 0 !important;
  overflow: visible !important;
  position: static !important;
  width: auto !important;
}

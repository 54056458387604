///* ========================================================================
//   #TYPOGRAPHY
//   ======================================================================== */

@mixin heading {
  font-family: $font-secondary;
  font-weight: bold;
  line-height: 1.3;
}

@mixin text-bigger {
  @include bp(large) {
    font-size: 1.8rem;
  }
}

@mixin text-lead {
  line-height: 1.4;

  @include bp(large) {
    font-size: 2.4rem;
  }
}

@mixin text-big {
  line-height: 1.4;

  @include bp(large) {
    font-size: 2.8rem;
  }
}

/* ==========================================================================
   #GALLERY
   ========================================================================== */

.c-gallery__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;

  @include bp(medium) {
    margin-right: -1.5rem;
  }
}

.c-gallery__item {
  text-align: center;
  width: calc(50% - 0.5rem);
  overflow: hidden;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  @include bp(large) {
    width: calc(33% - 1.5rem);
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @include bp(xlarge) {
    width: calc(25% - 1.5rem);
  }
}

.c-gallery__link {
  position: relative;
  display: block;

  &:after {
    @include fill-abs;
    content: '';
    background: $color-primary;
    transition: $global-transition;
    opacity: 0;
  }

  &:hover,
  .js-focus-visible &:focus:not(.focus-visible) {
    transform: scale(1.2);

    &:after {
      opacity: 0.2;
    }
  }
}

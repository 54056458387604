/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-primary;
  font-size: 62.5%; /* [1] */
  line-height: 1.6; /* [1] */
  min-height: 100%; /* [2] */
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

::selection {
  color: $color-white;
  background: $color-primary;
}

::-moz-selection {
  color: $color-white;
  background: $color-primary;
}

@mixin focus {
  background-color: $color-focus-bg;
  outline: 3px solid $color-focus-bg;
}

:focus {
  @include focus;
}

.js-focus-visible :focus:not(.focus-visible) {
  background: transparent;
  outline: none;
}
